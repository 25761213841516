import { UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';

export class CustomUrlSerializer implements UrlSerializer {
    parse(url: any): UrlTree {
        let dus = new DefaultUrlSerializer();
        return dus.parse(url);
    }

    serialize(tree: UrlTree): any {
        let dus = new DefaultUrlSerializer();
        return dus.serialize(tree)
            .replace(/%3B/g, ';')
            .replace(/%3D/g, '=')
            .replace(/%2C/g, ',')
            .replace(/%20/g, ' ')
            .replace(/%25/g, '%')
            .replace(/%23/g, '#')
            .replace(/%3F/g, '?');
    }
}
