import { Inject, Injectable } from '@angular/core';
import { Product } from '../interfaces/product';
import { OrderItem } from '../interfaces/order';
import { CartItem } from '../api';
import { DOCUMENT } from '@angular/common';

export interface GtmListData {
    productList: Product[];
    listName?: string;
    startIndex?: number;
}

export interface GtmCartData {
    items: CartItem[];
}

export interface GtmTransaction {
    id: string,
    affiliation: string,
    value: string,
    shipping: string,
    currency?: string,
    coupon: string,
    customerType: 'b2b' | 'b2c',
    items: OrderItem[],
}

export interface GtmRegister {
    phone: string;
    email: string;
    source: 'google' | 'form';
}

// export type DataLayer = {
//     push: (data: Record<string, unknown>) => void;
// };
//
// export function withWindowProperty<T>(prop: string, callback: (obj: T) => void) {
//     const obj = (window as any)[prop] as T;
//     console.log(obj)
//     if (obj) {
//         callback(obj);
//     }
// }

export type GtmEvent = 'view_item_list' | 'select_item' | 'view_item' | 'add_to_cart' | 'remove_from_cart'
    | 'begin_checkout' | 'purchase' | 'refund';

@Injectable({
    providedIn: 'root',
})
export class GtmService {

    constructor(@Inject(DOCUMENT) private document: Document) {
    }

    pushToDataLayer(data: Record<string, unknown>) {
        // console.log(data)
        // withWindowProperty<DataLayer>('dataLayer', ({ push }) => push(data));

        const window = document.defaultView;
        if (window) {
            const obj = (window as any)['dataLayer'];
            if (obj) {
                obj.push(data);
            }
        }
    }

    private getProductCategories(product: Product) {
        if (product.categories) {
            return product.categories.reduce((acc: { [key: string]: string }, category, i) => {
                const index = i === 0 ? '' : i + 1;
                acc[`item_category${index}`] = category.name;
                return acc;
            }, {});
        }
        return undefined;
    }

    private getProductsItems(data: GtmListData) {
        let items = [];

        const startIndex = data.startIndex ?? 1;

        items = data.productList.map((product: Product, index) => ({
            ...this.mapProduct(product),
            item_list_name: data.listName,
            index: startIndex + index,
            quantity: 1,
            ...(data.listName ? { item_list_name: data.listName } : {}),
        }));

        return items;
    }

    private getCartItems(data: GtmCartData) {
        let items = [];

        const startIndex = 1;

        items = data.items.map((item: CartItem, index) => ({
            ...this.mapProduct(item.product),
            index: startIndex + index,
            quantity: item.quantity,
        }));

        return items;
    }

    private getOrderItems(data: GtmTransaction) {
        const startIndex = 1;
        let items = [];
        items = data.items.map((item: OrderItem, index) => ({
            ...this.mapProduct(item.product),
            price: item.price,
            index: startIndex + index,
            quantity: item.quantity,
        }));

        return items;
    }

    private mapProduct(product: Product) {
        return {
            item_name: product.name,
            item_id: product.id,
            item_slug: product.slug,
            ...(product.price ? { price: product.price } : {}),
            item_brand: product.brand?.name,
            ...this.getProductCategories(product),
        }
    }

    /**
     * Формирование списка товаров в категории
     *
     * @param {GtmListData} data - The list data containing the items to be viewed.
     */
    viewItemList(data: GtmListData) {
        this.pushToDataLayer({ ecommerce: null });

        const items = this.getProductsItems(data);

        this.pushToDataLayer({
            event: 'view_item_list',
            ecommerce: {
                items: items,
            },
        });
    }

    /**
     * Клик по товару
     *
     * @param data
     */
    selectItem(data: GtmListData) {
        this.pushToDataLayer({
            event: 'select_item',
            ecommerce: {
                items: this.getProductsItems(data),
            },
        });
    }

    /**
     * Просмотр товара
     *
     * @param data
     */
    viewItem(data: GtmListData) {
        this.pushToDataLayer({
            event: 'view_item',
            ecommerce: {
                items: this.getProductsItems(data),
            },
        });
    }

    /**
     * Добавление в корзину
     *
     * @param data
     */
    addToCart(data: GtmCartData) {
        this.pushToDataLayer({
            event: 'add_to_cart',
            ecommerce: {
                items: this.getCartItems(data),
            },
        });
    }

    /**
     * Удаление из корзины
     *
     * @param data
     */
    removeFromCart(data: GtmCartData) {
        this.pushToDataLayer({
            event: 'remove_from_cart',
            ecommerce: {
                items: this.getCartItems(data),
            },
        });
    }

    /**
     * Переход на страницу заказа
     *
     * @param data
     */
    beginCheckout(data: GtmCartData) {
        this.pushToDataLayer({
            event: 'begin_checkout',
            ecommerce: {
                items: this.getCartItems(data),
            },
        });
    }

    /**
     * Совершённая покупка
     *
     * @param transaction
     */
    purchase(transaction: GtmTransaction) {
        this.pushToDataLayer({
            event: 'purchase',
            ecommerce: {
                transaction_id: transaction.id,
                affiliation: transaction.affiliation,
                customer_type: transaction.customerType,
                value: transaction.value,
                shipping: transaction.shipping,
                currency: transaction.currency,
                coupon: transaction.coupon,
                items: this.getOrderItems(transaction),
            },
        });
    }

    /**
     * Отмена покупки
     *
     * @param transactionId
     */
    refund(transactionId: string) {
        this.pushToDataLayer({
            event: 'refund',
            ecommerce: {
                transaction_id: transactionId,
            },
        });
    }

    checkoutRegister(data: GtmRegister) {
        this.pushToDataLayer({
            event: 'registr_ok',
            phone_number: data.phone,
            email: data.email,
            register_source: data.source,
        })
    }
}
