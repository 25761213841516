import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable({
    providedIn: 'root',
})
export class RefreshService {

    constructor(
        private router: Router,
    ) {}

    currentRoute() {
        void this.router.navigate([], {
            skipLocationChange: true,
            queryParamsHandling: 'merge',
        })
    }
}
